import styled from "styled-components"
import colors from "shared/utils/colors"

const radius = "3px"
const thBorder = `rgb(202,204,207)`
const tableBorder = `rgb(234,236,238)`

export const Table = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  font-size: 1.5rem;
  color: rgb(112, 114, 115);
  margin-bottom: 2rem;

  ${props =>
    props.dynamicWidth &&
    ` width: auto;
      max-width: 100%;
      margin: 0 auto;`};

  ${props =>
    !props.noRadius &&
    `
    border-radius: ${radius} ${radius} 0 0;
  `};
`

export const TableHead = styled.thead`
  border-radius: ${radius} ${radius} 0 0;
  th {
    overflow: hidden;
    box-sizing: border-box;
    background-color: ${colors.graySolitude};
    text-align: left;
    font-weight: 600;
    line-height: 2rem;
    padding: 1rem 1.5rem;
    border-top: 1px solid ${thBorder};
    border-bottom: 1px solid ${thBorder};
  }

  th:first-of-type {
    border-radius: ${radius} 0 0 0;
    border-left: 1px solid ${thBorder};
  }

  th:last-of-type {
    border-radius: 0 ${radius} 0 0;
    border-right: 1px solid ${thBorder};
  }
`

export const TableBody = styled.tbody`
  background: ${tableBorder};

  td {
    padding: 1rem 1.5rem;
    border-top: 1px solid ${tableBorder};
  }

  td:last-of-type {
    border-right: 1px solid ${tableBorder};
  }

  tr:nth-child(odd) {
    background: white;
  }
  tr:nth-child(even) {
    background: ${colors.grayWhiteSmoke};
  }
  tr:last-of-type td {
    border-bottom: 1px solid ${tableBorder};
  }

  ${props =>
    props.withSelect
      ? `
    tr {
      td:nth-child(2){
        border-left: 1px solid ${tableBorder};
      }
    }
    `
      : `
    td:first-of-type{
      border-left: 1px solid ${tableBorder};
    }
    `};
`

export const ClickableRow = styled.tr`
  cursor: pointer;
  &:hover {
    background: ${colors.grayWhisper} !important;
  }
`

export const SelectAll = styled.th``

export const CapitalizedCell = styled.td`
  &:first-letter {
    text-transform: capitalize;
  }
`

export const FirstHeader = styled.th`
  border-radius: ${radius} 0 0 0;
  border-left: 1px solid ${thBorder};
`

export const EmptySelectorCell = styled.th`
  width: 4.2rem;
  text-align: left;
  padding: 1rem 1rem 1rem 0 !important;
  background: white !important;
  border: none !important;
`

export const RowSelector = styled.td`
  width: 4.2rem;
  text-align: left;
  padding: 1.3rem 1rem 1rem 0 !important;
  background: white;
  border: none !important;
`

export const NoTableResults = styled.td`
  padding: 10rem 0 !important;
  text-align: center;
  a {
    color: ${props => props.theme.colors.primary};
    &:visited {
      color: ${props => props.theme.colors.primary};
    }
  }
  span {
    color: ${props => props.theme.colors.primary};
    &:hover {
      cursor: pointer;
    }
  }
`
